/* App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
}

header {
  background-color: #a31317;
  color: white;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 36px;
  margin: 0;
}

h2 {
  font-size: 24px;
  color: #333;
}

.container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input, select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #a31317;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #870e13;
}

.step-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.step-indicator div {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #f1f1f1;
  border-right: 1px solid #ddd;
}

.step-indicator div:last-child {
  border-right: none;
}

.step-indicator .active {
  background-color: #a31317;
  color: white;
}
